<template>
  <div class="req-temp-container">
    <vs-row>
      <vs-col vs-type="flex" vs-w="12">
        <vs-card class="custom-vx-card">
          <vs-button v-if="hasPermission" flat v-round class="flex primary ml-auto mr-5" @click="$router.push({ name: 'introducers-create' })">New introducer</vs-button>
          <vs-table :data="introducers" class="mt-5">
            <template slot="header">
              <vs-select label="Show records" v-model="limit" class="mb-4">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in limitValue" />
              </vs-select>
            </template>
            <template slot="thead">
              <vs-th>Introducer</vs-th>
              <vs-th>Introducer ID</vs-th>
              <vs-th></vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.name">{{ tr.name }}</vs-td>
                <vs-td :data="tr.introducerId">{{ tr.introducerId }}</vs-td>
                <vs-td :data="tr">
                  <vs-button flat v-round class="flex primary ml-auto mr-5" @click="introducerLogin(tr._id)">
                    Login
                  </vs-button>
                </vs-td>
                <vs-td :data="tr">
                  <a @click="handleEdit(tr._id)" style="text-decoration: underline; cursor: pointer; margin-left: 10px;">Edit</a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="mt-3">
            <v-pagination v-model="pageNumber" :page-count="totalPage"></v-pagination>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import vPagination from "@/views/components/pagination";

export default {
  components: { vPagination },

  data() {
    return {
      limitValue: [
        { text: "1", value: 1 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      introducerUrl: process.env.VUE_APP_INTRODUCER_APP,
      pageNumber: 1,
      totalData: 0,
      limit: 50,
      introducers: [],
      hasPermission: true
    };
  },

  methods: {
    ...mapActions("introducer", ["fetchAllIntroducers"]),

    ...mapActions("admin", ["checkLogin"]),

    async getListSetting() {
      const payload = {
        name: this.$route.name,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };
      await this.$store.dispatch("listSetting/updatelistSetting", payload).then((res) => {
        this.limit = parseInt(res.data.data.limit) || this.limit;
      }).catch((ex) => {});
    },

    async saveListSetting(limit) {
      const payload = {
        name: this.$route.name,
        limit,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };
      await this.$store.dispatch("listSetting/updatelistSetting", payload).then((res) => {})
        .catch((err) => {});
    },

    async getIntroducers(page, limit = this.limit) {
      const params = `?page=${page}&limit=${limit}&sort=desc`;
      await this.fetchAllIntroducers(params).then((res) => {
        this.introducers = res.data.data.docs;
        this.totalData = res.data.data.pagination.total ? res.data.data.pagination.total : 0;
      }).catch((err) => {});
    },

    handleEdit(id) {
      return this.$router.push({
        name: "introducers-edit",
        params: { id: id },
      });
    },

    async checkAdminLogin() {
      await this.checkLogin().then(res => {
        this.hasPermission = res.data.data ? res.data.data.permissions["introducers"] : false;
      });
    },

    introducerLogin(id) {
      let introducer = `${this.introducerUrl}login-as-introducer?token=${localStorage.getItem("accessToken")}&introducerId=${id}`;
      window.open(introducer, "_blank");
    },
  },

  watch: {
    pageNumber(val) {
      this.getIntroducers(val, this.limit);
    },

    limit(val) {
      this.pageNumber = 1;
      this.getIntroducers(this.pageNumber, val);
      this.saveListSetting(val);
    },
  },

  created() {
    this.checkAdminLogin();
    this.getListSetting();
    this.getIntroducers(this.pageNumber, this.limit);
  },

  computed: {
    totalPage: function () {
      return Math.ceil(this.totalData / this.limit);
    },
  },
};
</script>

