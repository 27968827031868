var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:_vm.paginationClasses.ul},[(_vm.paginationLabels.first)?_c('li',{staticClass:"first",class:`${_vm.paginationClasses.li} ${
      _vm.hasFirst ? _vm.paginationClasses.liDisable : ''
    }`},[_c('chevrons-left-icon',{class:`${_vm.paginationClasses.button} ${
        _vm.hasFirst ? _vm.paginationClasses.buttonDisable : ''
      }`,attrs:{"disabled":_vm.hasFirst,"size":_vm.chevronSize},on:{"click":_vm.first}})],1):_vm._e(),(_vm.paginationLabels.prev)?_c('li',{class:`${_vm.paginationClasses.li} ${
      _vm.hasFirst ? _vm.paginationClasses.liDisable : ''
    }`},[_c('chevron-left-icon',{class:`${_vm.paginationClasses.button} ${
        _vm.hasFirst ? _vm.paginationClasses.buttonDisable : ''
      }`,attrs:{"disabled":_vm.hasFirst,"size":_vm.chevronSize},on:{"click":_vm.prev}})],1):_vm._e(),_c('li',[_c('p',{staticClass:"text-base pagination-link cursor-default"},[_vm._v(" Page "+_vm._s(_vm.value)+" of "+_vm._s(_vm.pageCount)+" ")])]),(_vm.paginationLabels.next)?_c('li',{class:`${_vm.paginationClasses.li} ${
      _vm.hasLast ? _vm.paginationClasses.liDisable : ''
    }`},[_c('chevron-right-icon',{class:`${_vm.paginationClasses.button} ${
        _vm.hasLast ? _vm.paginationClasses.buttonDisable : ''
      }`,attrs:{"disabled":_vm.hasLast,"size":_vm.chevronSize},on:{"click":_vm.next}})],1):_vm._e(),(_vm.paginationLabels.last)?_c('li',{staticClass:"last",class:`${_vm.paginationClasses.li} ${
      _vm.hasLast ? _vm.paginationClasses.liDisable : ''
    }`},[_c('chevrons-right-icon',{class:`${_vm.paginationClasses.button} ${
        _vm.hasLast ? _vm.paginationClasses.buttonDisable : ''
      }`,attrs:{"disabled":_vm.hasLast,"size":_vm.chevronSize},on:{"click":_vm.last}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }